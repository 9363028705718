<div class="app-container summary-screen">
  <app-app-header />
  <div class="summary-screen__body">
    <h1 class="summary-screen__body__title">{{ 'MEAL SUMMARY' | translate }}</h1>
    <div class="summary-screen__body__calender">
      <!-- <a class="summary-screen__body__calender__date">Dec 30, 2019 (GMT+8)</a> -->
      <input
        type="date"
        [value]="formattedDate"
        (change)="dateChange($event)"
        class="summary-screen__body__calender__date"
      />
      <a class="summary-screen__body__calender__export" (click)="showExport()">{{
        'EXPORT' | translate
      }}</a>
    </div>
    <div class="export" id="contentToExport">
      <div class="meal-summary">
        <h2
          *ngIf="
            homeData !== undefined &&
            homeData.user_name !== undefined &&
            homeData.user_name !== null &&
            homeData.user_name !== ''
          "
        >
          {{ homeData.user_name }}
        </h2>
        <div class="meal-summary__table" *ngIf="homeData !== undefined">
          <div class="meal-summary__table__col">
            <p>&nbsp;</p>
            <p>{{ 'GOAL:' | translate }}</p>
            <p>{{ 'CURRENT:' | translate }}</p>
            <p>{{ 'REMAINING:' | translate }}</p>
          </div>
          <div class="meal-summary__table__col">
            <p>{{ 'MAIN 🍚' | translate }}</p>
            <p>{{ homeData.goal.rice_noodels }}</p>
            <p>{{ homeData.current_code.rice_noodels }}</p>
            <p>{{ homeData.remaning_code.rice_noodels }}</p>
          </div>
          <div class="meal-summary__table__col">
            <p>{{ 'MEAT 🥩' | translate }}</p>
            <p>{{ homeData.goal.meat }}</p>
            <p>{{ homeData.current_code.meat }}</p>
            <p>{{ homeData.remaning_code.meat }}</p>
          </div>
          <div class="meal-summary__table__col">
            <p>{{ 'VEGGIE 🥦' | translate }}</p>
            <p>{{ homeData.goal.vegitables }}</p>
            <p>{{ homeData.current_code.vegitables }}</p>
            <p>{{ homeData.remaning_code.vegitables }}</p>
          </div>
          <div class="meal-summary__table__col">
            <p>{{ 'FRUIT🍓' | translate }}</p>
            <p>{{ homeData.goal.fruits }}</p>
            <p>{{ homeData.current_code.fruits }}</p>
            <p>{{ homeData.remaning_code.fruits }}</p>
          </div>
          <div class="meal-summary__table__col">
            <p>{{ 'WATER 💧' | translate }}</p>
            <p>{{ homeData.goal.water }}</p>
            <p>{{ homeData.current_code.water }}</p>
            <p>{{ homeData.remaning_code.water }}</p>
          </div>
        </div>
      </div>

      <!-- <div
        class="supplement"
        *ngIf="
          homeData !== undefined &&
          homeData.assigned_suppliment !== undefined &&
          homeData.assigned_suppliment.length > 0
        "
      >
        <h2>Supplements</h2>
        <div class="supplement__cont">
          <div class="supplement__cont__box" *ngFor="let data of homeData.assigned_suppliment">
            <img
              src="{{ data.suppliment.image }}"
              width="54"
              height="17"
              alt="{{ data.suppliment.name }}"
            />
            <input
              type="text"
              disabled
              value="{{ getConsumedQuantity(data.suppliment) }} / {{ data.quantity }}"
            />
          </div>
        </div>
      </div> -->
      <div
        class="summary-screen__body__boxCont"
        *ngIf="homeData !== undefined && homeData.meal_information.length > 0"
      >
        <div
          class="summary-screen__body__box"
          *ngFor="let meal of homeData.meal_information; let i = index"
        >
          <!-- <p class="summary-screen__body__box__title">
            <span>{{
              meal.meal_type === '1'
                ? 'Breakfast'
                : meal.meal_type === '2'
                ? 'Lunch'
                : meal.meal_type === '3'
                ? 'Dinner'
                : meal.meal_type === '4'
                ? 'Snacks'
                : ''
            }}</span>

            {{ meal.meal_time.split('T')[1].split(':').slice(0, 2).join(':') }} (GMT+8)
          </p> -->
          <div class="summary-screen__body__box__mealDetail">
            <div class="summary-screen__body__box__mealDetail__imgs">
              <img
                src="{{ meal.meal_photo_1 }}"
                alt="meal photo"
                width="250"
                height="140"
                *ngIf="meal.meal_photo_1 !== null"
              />
              <img
                *ngIf="meal.meal_photo_2 !== null"
                src="{{ meal.meal_photo_2 }}"
                alt="meal photo"
                width="250"
                height="140"
              />
              <img
                src="{{ meal.meal_photo_3 }}"
                alt="meal photo"
                width="250"
                height="140"
                *ngIf="meal.meal_photo_3 !== null"
              />
            </div>
            <div class="summary-screen__body__box__mealDetail__detail">
              <p>
                <span>{{
                  meal.meal_type === '1'
                    ? 'Breakfast'
                    : meal.meal_type === '2'
                    ? 'Lunch'
                    : meal.meal_type === '3'
                    ? 'Dinner'
                    : meal.meal_type === '4'
                    ? 'Snacks'
                    : ''
                }}</span>

                {{ meal.meal_time.split('T')[1].split(':').slice(0, 2).join(':') }}
              </p>
              <p>
                <span>主</span
                >{{ meal.amount_of_rice_or_noodels !== null ? meal.amount_of_rice_or_noodels : 0 }}
              </p>
              <p><span>肉</span>{{ meal.amount_of_meat !== null ? meal.amount_of_meat : 0 }}</p>
              <p>
                <span>菜</span
                >{{ meal.amount_of_vegitables !== null ? meal.amount_of_vegitables : 0 }}
              </p>
              <p><span>果</span>{{ meal.amount_of_fruits !== null ? meal.amount_of_fruits : 0 }}</p>
              <p><span>水</span>{{ meal.amount_of_water !== null ? meal.amount_of_water : 0 }}</p>
              <!-- <div class="summary-screen__body__box__mealDetail__detail__divider"></div> -->
              <!-- <div *ngIf="meal.suppliment !== null && meal.suppliment.length > 0">
                <p *ngFor="let single_suppliment of meal.suppliment">
                  <span>{{ single_suppliment.suppliment.name }}</span
                  >{{ single_suppliment.quantity }}
                </p>
              </div> -->
            </div>
          </div>
          <div class="summary-screen__body__box__remarks" *ngIf="meal.remarks !== null">
            <p class="summary-screen__body__box__remarks__title">{{ 'REMARKS:' | translate }}</p>
            <p>{{ meal.remarks }}</p>
          </div>
        </div>
      </div>
      <div
        class="supplement"
        *ngIf="
          homeData !== undefined &&
          homeData.assigned_suppliment !== undefined &&
          homeData.assigned_suppliment.length > 0
        "
      >
        <h2>{{ 'SUPPLEMENTS' | translate }}</h2>
        <div class="supplement__cont">
          <div class="supplement__cont__box" *ngFor="let data of homeData.assigned_suppliment">
            <img
              src="{{ data.suppliment.image }}"
              width="54"
              height="17"
              alt="{{ data.suppliment.name }}"
            />
            <p class="supplement__cont__box__input">
              {{ getConsumedQuantity(data.suppliment) }} / {{ data.quantity }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="comment"
        *ngIf="
          homeData !== undefined &&
          homeData.meal_comment !== undefined &&
          homeData.meal_comment.length > 0
        "
      >
        <h2>{{ 'COMMENTS' | translate }}</h2>
        <pre>{{ homeData.meal_comment[0].comment }}</pre>
      </div>
    </div>
  </div>
  <app-app-footer />
  <div class="app-container export-popup" *ngIf="showExportPopup">
    <div class="export-popup__cont">
      <a class="export-popup__cont__close" (click)="hideExport()"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="12" fill="#E5E5E5" />
          <path
            d="M12 10.8891L15.8891 7L17 8.11094L13.1109 12L17 15.8891L15.8891 17L12 13.1109L8.11094 17L7 15.8891L10.8891 12L7 8.11094L8.11094 7L12 10.8891Z"
            fill="#626262"
          />
        </svg>
      </a>
      <p class="export-popup__cont__title">{{ "EXPORT TODAY'S SUMMARY" | translate }}</p>
      <div class="export-popup__cont__row">
        <a (click)="exportToPdf()">{{ 'EXPORT AS PDF' | translate }}</a>
        <a (click)="exportToJpg()">{{ 'EXPORT AS JPG' | translate }}</a>
      </div>
    </div>
  </div>
</div>
