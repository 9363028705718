<app-header />
<div class="topBanner">
  <div class="topBanner__cont">
    <img src="../../assets/images/background_banner.png" alt="banner" />
    <a class="topBanner__cont__logo">
      <img
        src="../../assets/images/logos_whatsapp-icon.svg"
        alt="whatsapp"
        width="80"
        height="80"
      />
      <p>立即查詢</p>
    </a>
  </div>
</div>

<div class="middleBanner">
  <div class="middleBanner__cont container">
    <img src="../../assets/images/logo.svg" alt="dream" width="163px" height="120px" />
    <div class="middleBanner__cont__text">
      <h5>健康飲食養成易瘦體質</h5>
      <p>
        試過生酮飲食甩頭髮？試過低碳飲食減左好多但係堅持唔到？<br />
        試過不同斷食方法見到少少但係停滯不前？ <br />
        試過減肥第一時間瘦個胸同塊面？
      </p>
      <p>想健康減重無副作用？想突破平台期唔反彈？<br />想唔洗捱餓輕鬆減磅？想脂肪走去對的位置？</p>
    </div>

    <div class="middleBanner__cont__para">
      <p>改善體質是目標，減重瘦身是成果</p>
      <p>容易複製的成功，為更多人帶來啟發</p>
      <h5>養生瘦身 ｜無需節食 ｜不靠運動｜獨家平台</h5>
    </div>
  </div>
</div>

<div class="submiddleBanner">
  <div class="submiddleBanner__cont container">
    <div class="submiddleBanner__cont__left">
      <div class="submiddleBanner__cont__left__top">
        <img src="../../assets/images/Group.png" alt=" group" width="285" height="48" />
      </div>
      <div class="submiddleBanner__cont__left__bottom">
        <p>
          DreamFit 養生瘦身結合中醫、自然療法及營養學原理締造一套獨家 健康飲食方法，<br />配合全天然食物提煉保健品，從根本解決亞健康問題，<br />養成健康易瘦體質，一個月可看到效果。
        </p>
        <p>
          由環球權威SGS 驗證，絕不含西藥類固醇、荷爾蒙、激素、重金屬、沒有副作用，保證安全有效。
        </p>
        <p>
          養生瘦身班由 DreamFit 專業團隊導師跟進，零風險，對健康無負擔，<br />一個月看到效果，保證飲食均衡，絕不捱餓飽住瘦。
        </p>
      </div>
    </div>
    <div class="submiddleBanner__cont__right">
      <div class="submiddleBanner__cont__right__content">
        <img src="../../assets/images/vector.svg" alt="vector" width="199px" height="199px" />
        <p>
          星級顧問營養學家&<br />
          星級全美維格爾認證師 <br /><br />Liz Tsang
        </p>
      </div>
      <div class="submiddleBanner__cont__right__content">
        <img src="../../assets/images/vector-1.svg" alt="vector-1" width="199px" height="199px" />
        <p>
          DreamFit創辦人&<br />
          星級全美維格爾認證師 <br /><br />Dorothy Law
        </p>
      </div>
      <div class="submiddleBanner__cont__right__content">
        <img src="../../assets/images/Vector-2.svg" alt="vector-2" width="199px" height="199px" />
        <p>星級養生瘦身導師<br /><br />XXXXX</p>
      </div>
    </div>
  </div>
</div>

<div class="bottomBanner">
  <div class="bottomBanner__cont container">
    <div class="bottomBanner__cont__img">
      <!-- <img src="../../assets/images/line.svg" alt="line" /> -->
      <img src="../../assets/images/dream.png" alt="DREAM" width="100%" height="100%" />
      <!-- <img src="../../assets/images/line-1.svg" alt="line1" /> -->
    </div>
    <div class="bottomBanner__cont__center">
      <div class="bottomBanner__cont__center__circle">
        <img src="../../assets/images/diet.png" alt="diet" width="324px" height="324px" />
        <!-- <p>締造獨家個人飲食密碼，無需捱餓，飽住瘦</p> -->
      </div>
      <div class="bottomBanner__cont__center__circle">
        <img src="../../assets/images/certified.png" alt="diet" width="324px" height="324px" />
        <!-- <p>取經台灣冠軍瘦身團隊獨家方法，專業營養師中醫師顧問</p> -->
      </div>
      <div class="bottomBanner__cont__center__circle">
        <img src="../../assets/images/computer.png" alt="diet" width="324px" height="324px" />
        <!-- <p>獨家用戶平台，每日專人跟進</p> -->
      </div>
      <div class="bottomBanner__cont__center__circle">
        <img src="../../assets/images/calendar.png" alt="diet" width="324px" height="324px" />
        <!-- <p>一個月可看到效果</p> -->
      </div>
      <div class="bottomBanner__cont__center__circle">
        <img src="../../assets/images/side.png" alt="diet" width="324px" height="324px" />
        <!-- <p>無副作用，解決亞健康和皮膚問題</p> -->
      </div>
      <div class="bottomBanner__cont__center__circle">
        <img src="../../assets/images/sgs.png" alt="diet" width="324px" height="324px" />
        <!-- <p>環球權威實驗室SGS驗證全天然食物提煉保健品安全有效</p> -->
      </div>
    </div>
  </div>
</div>

<div class="bottomSub">
  <div class="bottomSub__cont container">
    <div class="bottomSub__cont__top">
      <img src="../../assets/images/dream.svg" alt="dream" />
      <img src="../../assets/images/cantoneme.svg" alt="cantonese" />
    </div>
    <div class="bottomSub__cont__middle">
      <div class="bottomSub__cont__middle__month">
        <img src="../../assets/images/month.png" alt="month" width="328" height="328" />
        <p>
          學員A：<br />估唔到一個月就咁明顯，xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx`
        </p>
      </div>
      <div class="bottomSub__cont__middle__month">
        <img src="../../assets/images/month.png" alt="month" width="328" height="328" />
        <p>
          學員A：<br />估唔到一個月就咁明顯，xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx`
        </p>
      </div>
      <div class="bottomSub__cont__middle__month">
        <img src="../../assets/images/month.png" alt="month" width="328" height="328" />
        <p>
          學員A：<br />估唔到一個月就咁明顯，xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx`
        </p>
      </div>
    </div>
    <div class="bottomSub__cont__bottom">
      <img src="../../assets/images/menu.svg" alt="menu" width="115" height="18" />
    </div>
  </div>
</div>

<div class="bottomSUbBanner">
  <div class="bottomSUbBanner__cont container">
    <div class="bottomSUbBanner__cont__left">
      <div class="bottomSUbBanner__cont__left__text">
        <div class="bottomSUbBanner__cont__left__text__para">減肥迷思Q&A</div>
        <p>專業團隊回覆跟進</p>
      </div>
      <div class="bottomSUbBanner__cont__left__banner">
        <input
          type="button"
          value="有無副作用嫁？會唔會好易反彈？"
          class="bottomSUbBanner__cont__left__banner__primaryBtn"
        />
        <input
          type="button"
          value="絕無副作用，仲幫你養成易瘦體質！"
          class="bottomSUbBanner__cont__left__banner__secondaryBtn"
        />
        <input
          type="button"
          value="會唔會又要節食又要肚餓嫁？"
          class="bottomSUbBanner__cont__left__banner__primaryBtn"
        />
        <input
          type="button"
          value="一定唔會餓親，無需節食，無需運動！"
          class="bottomSUbBanner__cont__left__banner__secondaryBtn"
        />
      </div>
    </div>
    <div class="bottomSUbBanner__cont__right">
      <img src="../../assets/images/sing.png" alt="singing" width="537px" height="559px" />
    </div>
  </div>
</div>
