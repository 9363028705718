<div class="app-container profile-screen">
  <app-app-header />
  <div class="profile-screen__body">
    <h1 class="profile-screen__body__title">{{ 'USER PROFILE' | translate }}</h1>
    <form class="profile-screen__body__form" [formGroup]="ProfileForm">
      <div class="profile-screen__body__form__img">
        <img
          src="{{ profileImage }}"
          alt="User Image"
          width="180"
          height="180"
          *ngIf="profileImage !== ''"
        />
        <div class="profile-screen__body__form__img__placeholder" *ngIf="profileImage === ''"></div>
        <div class="profile-screen__body__form__img__upload">
          <input
            type="file"
            id="upload"
            hidden
            accept="image/png, image/jpeg"
            (change)="handleFileInput('user_image', $event)"
          />
          <label for="upload">{{ 'UPLOAD PHOTO' | translate }}</label>
        </div>
      </div>
      <div class="profile-screen__body__form__body">
        <div class="profile-screen__body__form__body__row">
          <div class="profile-screen__body__form__body__input">
            <label for="first_name">{{ 'FIRST NAME:' | translate }}</label>
            <input type="text" formControlName="first_name" value="Ravi" />
          </div>
          <div class="profile-screen__body__form__body__input">
            <label for="last_name">{{ 'LAST NAME:' | translate }}</label>
            <input type="text" formControlName="last_name" value="Patel" />
          </div>
        </div>
        <div class="profile-screen__body__form__body__row">
          <div class="profile-screen__body__form__body__input">
            <label for="dob">{{ 'BIRTHDAY:' | translate }}</label>
            <input
              type="date"
              formControlName="birth_date"
              value="16-11-1992"
              min="1964-01-01"
              placeholder="DD-MM-YYYY"
            />
            <mat-form-field>
              <input
                matInput
                [matDatepicker]="dobPicker"
                formControlName="birth_date"
                placeholder="DD-MM-YYYY"
                (focus)="openDatePicker(dobPicker)"
              />
              <mat-datepicker-toggle matIconSuffix [for]="dobPicker"></mat-datepicker-toggle>
              <mat-datepicker #dobPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="profile-screen__body__form__body__input">
            <label for="gender">{{ 'GENDER:' | translate }}</label>
            <select formControlName="gender">
              <option value="1">{{ 'MALE' | translate }}</option>
              <option value="2">{{ 'FEMALE' | translate }}</option>
            </select>
          </div>
        </div>
        <div class="profile-screen__body__form__body__row">
          <div class="profile-screen__body__form__body__input">
            <label for="fat_mass">{{ 'WEIGHT (KG):' | translate }}</label>
            <input
              type="number"
              formControlName="weight"
              (input)="calculateBodyMass()"
              maxlength="3"
            />
          </div>
          <div class="profile-screen__body__form__body__input">
            <label for="fat_mass">{{ 'HEIGHT (CM):' | translate }}</label>
            <input type="number" formControlName="height" maxlength="3" />
          </div>
        </div>
        <div class="profile-screen__body__form__body__row">
          <div class="profile-screen__body__form__body__input">
            <label for="body_fat">{{ 'BODY FAT(%):' | translate }}</label>
            <input type="number" formControlName="body_fat" (input)="calculateBodyMass()" />
          </div>
          <div class="profile-screen__body__form__body__input">
            <label for="fat_mass">{{ 'FAT MASS(KG):' | translate }}</label>
            <input
              type="number"
              formControlName="body_mass"
              readonly
              value="{{ calculatedBodyMass }}"
            />
          </div>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="waist">{{ 'WAIST (CM):' | translate }}</label>
          <input type="number" formControlName="waist" value="30" />
          <small class="profile-screen__body__form__body__input__info">{{
            'DECIMAL DATA IS NOT ALLOWED' | translate
          }}</small>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="hips">{{ 'HIPS (CM):' | translate }}</label>
          <input type="number" formControlName="hips" value="Patel" />
          <small class="profile-screen__body__form__body__input__info">{{
            'DECIMAL DATA IS NOT ALLOWED' | translate
          }}</small>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="Activity_level">{{ 'ACTIVITY LEVEL:' | translate }}</label>
          <select formControlName="activity_level">
            <option value="1">{{ 'SEDENTARY - LITTLE OR NO EXERCISE' | translate }}</option>
            <option value="2">{{ 'LIGHT ACTIVE - EXERCISE 1-2 DAYS/WEEK' | translate }}</option>
            <option value="3">{{ 'MODERATELY ACTIVE EXERCISE 3-5 DAYS/WEEK' | translate }}</option>
            <option value="4">{{ 'ACTIVE - EXERCISE 6-7 DAYS/WEEK' | translate }}</option>
            <option value="5">{{ 'VERY ACTIVE - HARD EXERCISE 6-7 DAYS/WEEK' | translate }}</option>
          </select>
        </div>
        <div class="profile-screen__body__form__body__bmi" *ngIf="BMI > 0">
          <p>
            <span>{{ 'YOUR BMI' | translate }}</span
            >: {{ BMI }}
          </p>
          <p>{{ 'YOUR BASE CALORIE REQURIEMENT:' | translate }} {{ bseCalorie }} calories</p>
          <p>{{ 'YOUR DAILY CALORIE REQUIREMENT:' | translate }} {{ dailyCalorie }} calories</p>
        </div>
        <!-- <div class="profile-screen__body__form__body__input">
          <label for="timezone">Select timezone:</label>
          <select formControlName="">
            <option>GMT +8</option>
            <option>GMT +4</option>
            <option>GMT +2</option>
            <option>GMT -2</option>
            <option>GMT -4</option>
          </select>
        </div> -->
        <div class="profile-screen__body__form__body__row">
          <div class="profile-screen__body__form__body__input">
            <label for="sleeping">{{ 'USUAL SLEEPING TIME:' | translate }}</label>
            <select formControlName="sleep_time">
              <option value="00:00:00">12:00 AM</option>
              <option value="00:15:00">12:15 AM</option>
              <option value="00:30:00">12:30 AM</option>
              <option value="00:45:00">12:45 AM</option>
              <option value="01:00:00">01:00 AM</option>
              <option value="01:15:00">01:15 AM</option>
              <option value="01:30:00">01:30 AM</option>
              <option value="01:45:00">01:45 AM</option>
              <option value="02:00:00">02:00 AM</option>
              <option value="02:15:00">02:15 AM</option>
              <option value="02:30:00">02:30 AM</option>
              <option value="02:45:00">02:45 AM</option>
              <option value="03:00:00">03:00 AM</option>
              <option value="03:15:00">03:15 AM</option>
              <option value="03:30:00">03:30 AM</option>
              <option value="03:45:00">03:45 AM</option>
              <option value="04:00:00">04:00 AM</option>
              <option value="04:15:00">04:15 AM</option>
              <option value="04:30:00">04:30 AM</option>
              <option value="04:45:00">04:45 AM</option>
              <option value="05:00:00">05:00 AM</option>
              <option value="05:15:00">05:15 AM</option>
              <option value="05:30:00">05:30 AM</option>
              <option value="05:45:00">05:45 AM</option>
              <option value="06:00:00">06:00 AM</option>
              <option value="06:15:00">06:15 AM</option>
              <option value="06:30:00">06:30 AM</option>
              <option value="06:45:00">06:45 AM</option>
              <option value="07:00:00">07:00 AM</option>
              <option value="07:15:00">07:15 AM</option>
              <option value="07:30:00">07:30 AM</option>
              <option value="07:45:00">07:45 AM</option>
              <option value="08:00:00">08:00 AM</option>
              <option value="08:15:00">08:15 AM</option>
              <option value="08:30:00">08:30 AM</option>
              <option value="08:45:00">08:45 AM</option>
              <option value="09:00:00">09:00 AM</option>
              <option value="09:15:00">09:15 AM</option>
              <option value="09:30:00">09:30 AM</option>
              <option value="09:45:00">09:45 AM</option>
              <option value="10:00:00">10:00 AM</option>
              <option value="10:15:00">10:15 AM</option>
              <option value="10:30:00">10:30 AM</option>
              <option value="10:45:00">10:45 AM</option>
              <option value="11:00:00">11:00 AM</option>
              <option value="11:15:00">11:15 AM</option>
              <option value="11:30:00">11:30 AM</option>
              <option value="11:45:00">11:45 AM</option>
              <option value="12:00:00">12:00 PM</option>
              <option value="12:15:00">12:15 PM</option>
              <option value="12:30:00">12:30 PM</option>
              <option value="12:45:00">12:45 PM</option>
              <option value="13:00:00">01:00 PM</option>
              <option value="13:15:00">01:15 PM</option>
              <option value="13:30:00">01:30 PM</option>
              <option value="13:45:00">01:45 PM</option>
              <option value="14:00:00">02:00 PM</option>
              <option value="14:15:00">02:15 PM</option>
              <option value="14:30:00">02:30 PM</option>
              <option value="14:45:00">02:45 PM</option>
              <option value="15:00:00">03:00 PM</option>
              <option value="15:15:00">03:15 PM</option>
              <option value="15:30:00">03:30 PM</option>
              <option value="15:45:00">03:45 PM</option>
              <option value="16:00:00">04:00 PM</option>
              <option value="16:15:00">04:15 PM</option>
              <option value="16:30:00">04:30 PM</option>
              <option value="16:45:00">04:45 PM</option>
              <option value="17:00:00">05:00 PM</option>
              <option value="17:15:00">05:15 PM</option>
              <option value="17:30:00">05:30 PM</option>
              <option value="17:45:00">05:45 PM</option>
              <option value="18:00:00">06:00 PM</option>
              <option value="18:15:00">06:15 PM</option>
              <option value="18:30:00">06:30 PM</option>
              <option value="18:45:00">06:45 PM</option>
              <option value="19:00:00">07:00 PM</option>
              <option value="19:15:00">07:15 PM</option>
              <option value="19:30:00">07:30 PM</option>
              <option value="19:45:00">07:45 PM</option>
              <option value="20:00:00">08:00 PM</option>
              <option value="20:15:00">08:15 PM</option>
              <option value="20:30:00">08:30 PM</option>
              <option value="20:45:00">08:45 PM</option>
              <option value="21:00:00">09:00 PM</option>
              <option value="21:15:00">09:15 PM</option>
              <option value="21:30:00">09:30 PM</option>
              <option value="21:45:00">09:45 PM</option>
              <option value="22:00:00">10:00 PM</option>
              <option value="22:15:00">10:15 PM</option>
              <option value="22:30:00">10:30 PM</option>
              <option value="22:45:00">10:45 PM</option>
              <option value="23:00:00">11:00 PM</option>
              <option value="23:15:00">11:15 PM</option>
              <option value="23:30:00">11:30 PM</option>
              <option value="23:45:00">11:45 PM</option>
            </select>
            <!-- <div>
              <input
                placeholder="HH:MM"
                aria-label="12hr format"
                formControlName="sleep_time"
                [ngxTimepicker]="sleep"
                [value]="'05:00 PM'"
                readonly
              />
              <ngx-material-timepicker #sleep></ngx-material-timepicker>
            </div> -->
            <!-- <input type="time" formControlName="sleep_time" placeholder="HH:MM" /> -->
          </div>
          <div class="profile-screen__body__form__body__input">
            <label for="wakeup">{{ 'USUAL WAKEUP TIME:' | translate }}</label>
            <select formControlName="wakeup_time">
              <option value="00:00:00">12:00 AM</option>
              <option value="00:15:00">12:15 AM</option>
              <option value="00:30:00">12:30 AM</option>
              <option value="00:45:00">12:45 AM</option>
              <option value="01:00:00">01:00 AM</option>
              <option value="01:15:00">01:15 AM</option>
              <option value="01:30:00">01:30 AM</option>
              <option value="01:45:00">01:45 AM</option>
              <option value="02:00:00">02:00 AM</option>
              <option value="02:15:00">02:15 AM</option>
              <option value="02:30:00">02:30 AM</option>
              <option value="02:45:00">02:45 AM</option>
              <option value="03:00:00">03:00 AM</option>
              <option value="03:15:00">03:15 AM</option>
              <option value="03:30:00">03:30 AM</option>
              <option value="03:45:00">03:45 AM</option>
              <option value="04:00:00">04:00 AM</option>
              <option value="04:15:00">04:15 AM</option>
              <option value="04:30:00">04:30 AM</option>
              <option value="04:45:00">04:45 AM</option>
              <option value="05:00:00">05:00 AM</option>
              <option value="05:15:00">05:15 AM</option>
              <option value="05:30:00">05:30 AM</option>
              <option value="05:45:00">05:45 AM</option>
              <option value="06:00:00">06:00 AM</option>
              <option value="06:15:00">06:15 AM</option>
              <option value="06:30:00">06:30 AM</option>
              <option value="06:45:00">06:45 AM</option>
              <option value="07:00:00">07:00 AM</option>
              <option value="07:15:00">07:15 AM</option>
              <option value="07:30:00">07:30 AM</option>
              <option value="07:45:00">07:45 AM</option>
              <option value="08:00:00">08:00 AM</option>
              <option value="08:15:00">08:15 AM</option>
              <option value="08:30:00">08:30 AM</option>
              <option value="08:45:00">08:45 AM</option>
              <option value="09:00:00">09:00 AM</option>
              <option value="09:15:00">09:15 AM</option>
              <option value="09:30:00">09:30 AM</option>
              <option value="09:45:00">09:45 AM</option>
              <option value="10:00:00">10:00 AM</option>
              <option value="10:15:00">10:15 AM</option>
              <option value="10:30:00">10:30 AM</option>
              <option value="10:45:00">10:45 AM</option>
              <option value="11:00:00">11:00 AM</option>
              <option value="11:15:00">11:15 AM</option>
              <option value="11:30:00">11:30 AM</option>
              <option value="11:45:00">11:45 AM</option>
              <option value="12:00:00">12:00 PM</option>
              <option value="12:15:00">12:15 PM</option>
              <option value="12:30:00">12:30 PM</option>
              <option value="12:45:00">12:45 PM</option>
              <option value="13:00:00">01:00 PM</option>
              <option value="13:15:00">01:15 PM</option>
              <option value="13:30:00">01:30 PM</option>
              <option value="13:45:00">01:45 PM</option>
              <option value="14:00:00">02:00 PM</option>
              <option value="14:15:00">02:15 PM</option>
              <option value="14:30:00">02:30 PM</option>
              <option value="14:45:00">02:45 PM</option>
              <option value="15:00:00">03:00 PM</option>
              <option value="15:15:00">03:15 PM</option>
              <option value="15:30:00">03:30 PM</option>
              <option value="15:45:00">03:45 PM</option>
              <option value="16:00:00">04:00 PM</option>
              <option value="16:15:00">04:15 PM</option>
              <option value="16:30:00">04:30 PM</option>
              <option value="16:45:00">04:45 PM</option>
              <option value="17:00:00">05:00 PM</option>
              <option value="17:15:00">05:15 PM</option>
              <option value="17:30:00">05:30 PM</option>
              <option value="17:45:00">05:45 PM</option>
              <option value="18:00:00">06:00 PM</option>
              <option value="18:15:00">06:15 PM</option>
              <option value="18:30:00">06:30 PM</option>
              <option value="18:45:00">06:45 PM</option>
              <option value="19:00:00">07:00 PM</option>
              <option value="19:15:00">07:15 PM</option>
              <option value="19:30:00">07:30 PM</option>
              <option value="19:45:00">07:45 PM</option>
              <option value="20:00:00">08:00 PM</option>
              <option value="20:15:00">08:15 PM</option>
              <option value="20:30:00">08:30 PM</option>
              <option value="20:45:00">08:45 PM</option>
              <option value="21:00:00">09:00 PM</option>
              <option value="21:15:00">09:15 PM</option>
              <option value="21:30:00">09:30 PM</option>
              <option value="21:45:00">09:45 PM</option>
              <option value="22:00:00">10:00 PM</option>
              <option value="22:15:00">10:15 PM</option>
              <option value="22:30:00">10:30 PM</option>
              <option value="22:45:00">10:45 PM</option>
              <option value="23:00:00">11:00 PM</option>
              <option value="23:15:00">11:15 PM</option>
              <option value="23:30:00">11:30 PM</option>
              <option value="23:45:00">11:45 PM</option>
            </select>
            <!-- <div>
              <input
                placeholder="HH:MM"
                aria-label="12hr format"
                formControlName="wakeup_time"
                [ngxTimepicker]="wakeup"
                readonly
              />
              <ngx-material-timepicker #wakeup></ngx-material-timepicker>
            </div> -->
            <!-- <input type="time" formControlName="wakeup_time" placeholder="HH:MM" /> -->
          </div>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="sleep_quality">{{ 'SLEEP QUALITY:' | translate }}</label>
          <select formControlName="sleep_quality">
            <option value="1">{{ 'EASY TO WAKEUP FROM LIGHT SLEEP' | translate }}</option>
            <option value="2">{{ 'GOOD SLEEP QUALITY' | translate }}</option>
            <option value="3">
              {{ 'SLEEP QUALITY IS GOOD BUT DIFFICULT TO FALL SLEEP' | translate }}
            </option>
            <option value="4">{{ 'LIGHT SLEEP' | translate }}</option>
          </select>
        </div>
        <div
          class="profile-screen__body__form__body__input"
          *ngIf="ProfileForm.value.gender === '2'"
        >
          <label for="sleep_quality">{{
            'DO YOU HAVE YOUR DISCOUMFORT BEFORE AND AFTER MENSTRUAL.OR THE CONDITION OF THE SKIN HAS DETERIORATED SIGNIFICANTLY?'
              | translate
          }}</label>
          <select formControlName="meanstation_cycle">
            <option value="true">{{ 'YES' | translate }}</option>
            <option value="false">{{ 'NO' | translate }}</option>
          </select>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="illnesses">{{ 'LIST ALL LONG-TERM ILLNESSES HERE :' | translate }}</label>
          <textarea rows="3" formControlName="family_history">Sugar</textarea>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="medicines">{{
            'TAKING REGULAR MEDICINES? IF YES, LIST HERE :' | translate
          }}</label>
          <textarea rows="3" formControlName="regular_medicine">Paracetamol</textarea>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="medicines">{{
            'DO YOU HAVE FAMILY HISTORY OF DIABETES, HYPERTENSION, HIGH CHOLESTEROL, HEART ATTACK OR STROKE?'
              | translate
          }}</label>
          <textarea rows="3" formControlName="cronical_information"></textarea>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="allergies">{{ 'LIST FOOD ALLERGIES, IF ANY:' | translate }}</label>
          <textarea rows="3" formControlName="food_allergy">Lorem ipsum</textarea>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="food_preference">{{ 'FOOD PREFERENCE:' | translate }}</label>
          <div class="profile-screen__body__form__body__input__checkbox">
            <!-- <label class="customCheckbox" *ngFor="let option of options">
              <input
                type="checkbox"
                [value]="option.value"
                [checked]="isSelected(option.value)"
                (change)="toggleOption(option.value)"
              />
              <span class="checkmark"></span>
              {{ option.label }}
            </label> -->
            <label class="customCheckbox">
              <input
                type="checkbox"
                [value]="1"
                [checked]="isSelected(1)"
                (change)="toggleOption(1)"
              />
              <span class="checkmark"></span>
              NA
            </label>
            <label class="customCheckbox">
              <input
                type="checkbox"
                [value]="2"
                [checked]="isSelected(2)"
                (change)="toggleOption(2)"
              />
              <span class="checkmark"></span>
              {{ 'SWEET' | translate }}
            </label>
            <label class="customCheckbox">
              <input
                type="checkbox"
                [value]="3"
                [checked]="isSelected(3)"
                (change)="toggleOption(3)"
              />
              <span class="checkmark"></span>
              {{ 'SPICY FOOD' | translate }}
            </label>
            <label class="customCheckbox">
              <input
                type="checkbox"
                [value]="4"
                [checked]="isSelected(4)"
                (change)="toggleOption(4)"
              />
              <span class="checkmark"></span>
              {{ 'STRONG FOOD' | translate }}
            </label>
            <label class="customCheckbox">
              <input
                type="checkbox"
                [value]="5"
                [checked]="isSelected(5)"
                (change)="toggleOption(5)"
              />
              <span class="checkmark"></span>
              {{ 'DIARY PRODUCT' | translate }}
            </label>
            <label class="customCheckbox">
              <input
                type="checkbox"
                [value]="6"
                [checked]="isSelected(6)"
                (change)="toggleOption(6)"
              />
              <span class="checkmark"></span>
              {{ 'SNACKS' | translate }}
            </label>
          </div>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="bowel_movement">{{ 'HOW OFTEN YOU HAVE BOWEL MOVEMENT?' | translate }}</label>
          <select formControlName="bowl_movememnt_trend">
            <option value="1">{{ 'SEVERAL TIMES A DAY' | translate }}</option>
            <option value="2">{{ 'ONCE A DAY' | translate }}</option>
            <option value="3">{{ 'EVERY OTHER DAY' | translate }}</option>
            <option value="4">{{ 'LESS THAN 3 TIMES A WEEK' | translate }}</option>
          </select>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="bowel_movement">{{ 'USUAL STOOL PATTERN:' | translate }}</label>
          <select formControlName="normal_stool_form">
            <option value="1">{{ 'NORMAL BANANA SHAPE' | translate }}</option>
            <option value="2">{{ 'HARD AND FINE-GRAINED' | translate }}</option>
            <option value="3">{{ 'PULPY WATERY' | translate }}</option>
          </select>
        </div>
        <div class="profile-screen__body__form__body__input">
          <label for="bowel_movement">{{ 'WORK STRESS INDEX:' | translate }}</label>
          <select formControlName="work_stress_index">
            <option value="1">{{ '1 - LOW' | translate }}</option>
            <option value="2">{{ '2' | translate }}</option>
            <option value="3">{{ '3' | translate }}</option>
            <option value="4">{{ '4' | translate }}</option>
            <option value="5">{{ '5 - HIGH' | translate }}</option>
          </select>
        </div>
        <div class="profile-screen__body__form__body__btn">
          <input
            type="button"
            value="{{ 'SAVE' | translate }}"
            (click)="submitProfile()"
            class="profile-screen__body__form__body__btn__submit"
          />
        </div>
      </div>
    </form>
  </div>
  <div class="loading" *ngIf="loading">
    <div class="loader"></div>
  </div>
  <app-app-footer />
</div>
