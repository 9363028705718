<div class="registration">
  <div class="registration__cont appcontainer">
    <div class="registration__cont__lang">
      <app-language-selector></app-language-selector>
    </div>
    <div class="registration__cont__top">
      <img src="../../../../assets/images/logo1.png" width="140" height="102" />
      <p>{{ 'REGISTER' | translate }}</p>
    </div>
    <form class="registration__cont__form" [formGroup]="registrationForm">
      <ng-container *ngIf="!otpVerified">
        <div
          [ngClass]="{
            input: true,
            input___error: countryCode && registrationForm.get('country_code')?.hasError('required')
          }"
        >
          <select formControlName="country_code" placeholder="Select Country Code">
            <option value="" disabled>{{ 'SELECT COUNTRY CODE' | translate }}</option>
            <option value="+852">+852</option>
            <option value="+86">+86</option>
            <option value="+81">+81</option>
          </select>
          <p
            class="input__errorMsg"
            *ngIf="registrationForm.get('country_code')?.hasError('required')"
          >
            {{ 'COUNTRY CODE IS REQUIRED.' | translate }}
          </p>
        </div>
        <div
          [ngClass]="{
            input: true,
            relative: true,
            input___error:
              phoneNumber &&
              (registrationForm.get('phone_number')?.hasError('required') ||
                registrationForm.get('phone_number')?.hasError('pattern'))
          }"
        >
          <input
            type="text"
            placeholder="85558555"
            id="phone_number"
            formControlName="phone_number"
            (input)="resetError()"
          />
          <p
            class="input__errorMsg"
            *ngIf="registrationForm.get('phone_number')?.hasError('required')"
          >
            {{ 'MOBILE NUMBER IS REQUIRED.' | translate }}
          </p>
          <p
            class="input__errorMsg"
            *ngIf="registrationForm.get('phone_number')?.hasError('pattern')"
          >
            {{ 'PLEASE ENTER A VALID 8-DIGIT MOBILE NUMBER.' | translate }}
          </p>
        </div>
        <div
          [ngClass]="{
            input: true,
            input___error:
              otp &&
              (registrationForm.get('otp')?.hasError('required') ||
                registrationForm.get('otp')?.hasError('pattern'))
          }"
          *ngIf="otpSent && registrationForm.get('phone_number')!.valid"
        >
          <input
            type="text"
            placeholder="Please Enter OTP"
            formControlName="otp"
            (input)="resetError()"
            [readOnly]="otpVerified"
          />
          <p class="input__errorMsg" *ngIf="registrationForm.get('otp')?.hasError('required')">
            {{ 'OTP IS REQUIRED.' | translate }}
          </p>
          <p class="input__errorMsg" *ngIf="registrationForm.get('otp')?.hasError('pattern')">
            {{ ' PLEASE ENTER A VALID 4-DIGIT OTP.' | translate }}
          </p>
        </div>
        <div
          [ngClass]="{
            input: true,
            input___error:
              invitationCode &&
              (registrationForm.get('invitation_code')?.hasError('required') ||
                registrationForm.get('invitation_code')?.hasError('pattern'))
          }"
          *ngIf="otpSent && registrationForm.get('phone_number')!.valid"
        >
          <input
            type="number"
            placeholder="Enter Invitation Code"
            formControlName="invitation_code"
          />

          <p class="input__errorMsg">{{ 'INVITATION CODE IS REQUIRED.' | translate }}</p>
        </div>
      </ng-container>
      <ng-container *ngIf="otpVerified">
        <div
          [ngClass]="{
            input: true,
            input___error: password && registrationForm.get('password')?.hasError('required')
          }"
        >
          <label><span>*</span>{{ 'PASSWORD' | translate }}</label>
          <input
            type="password"
            placeholder="**************"
            formControlName="password"
            (input)="resetError()"
          />
          <p class="input__errorMsg" *ngIf="registrationForm.get('password')?.hasError('required')">
            {{ 'PASSWORD IS REQUIRED.' | translate }}
          </p>
        </div>
        <div
          [ngClass]="{
            input: true,
            input___error:
              passwordMismatch ||
              (confirmPassword && registrationForm.get('confirmPassword')?.hasError('required'))
          }"
        >
          <label><span>*</span>{{ 'CONFIRM PASSWORD IS REQUIRED.' | translate }}</label>
          <input
            type="password"
            placeholder="**************"
            formControlName="confirmPassword"
            (input)="resetError()"
          />
          <p
            class="input__errorMsg"
            *ngIf="registrationForm.get('confirmPassword')?.hasError('required')"
          >
            {{ 'CONFIRM PASSWORD IS REQUIRED.' | translate }}
          </p>
          <p class="input__errorMsg" *ngIf="passwordMismatch">Passwords do not match.</p>
        </div>
      </ng-container>
      <div class="registration__cont__form__forgot">
        <!-- <a href="">Forgot your password?</a> -->
        <input
          type="button"
          [value]="'SEND OTP' | translate"
          class="primaryBtn"
          (click)="sendOTP()"
          *ngIf="!otpSent"
        />
        <input
          type="button"
          value="Verify OTP & Invitation Code"
          class="primaryBtn"
          (click)="verifyOTP()"
          *ngIf="otpSent && registrationForm.get('phone_number')!.valid && !otpVerified"
        />
        <input
          type="button"
          value="Register"
          class="primaryBtn"
          (click)="onSubmit()"
          *ngIf="otpVerified"
        />
      </div>
      <a class="secondaryBtn" href="{{ currentLang }}/login">{{
        'ALREADY HAVE AN ACCOUNT?' | translate
      }}</a>
    </form>
  </div>
</div>
