<div class="status">
  <div class="status__cont appcontainer">
    <div class="status__cont__icon">
      <div class="status__cont__icon__home">
        <img src="../../../../assets/images/home.svg" alt="home" />
        <p>{{ 'HOME' | translate }}</p>
      </div>
      <div class="status__cont__icon__calendar">
        <img src="../../../../assets/images/calendar.svg" alt="calendar" />
        <p>{{ 'CALENDAR' | translate }}</p>
      </div>
      <div class="status__cont__icon__calendar">
        <img src="../../../../assets/images/trend.svg" alt="trend" />
        <p>{{ 'TREND' | translate }}</p>
      </div>
      <div class="status__cont__icon__calendar">
        <img src="../../../../assets/images/profile.svg" alt="profile" />
        <p>{{ 'PROFILE' | translate }}</p>
      </div>
    </div>
    <div class="status__cont__content">
      <div class="status__cont__content__logo">
        <img src="../../../../assets/images/dreamfit.png" alt="logo" />
      </div>
      <div class="status__cont__content__mission">
        <div class="status__cont__content__mission__cal">
          <p>{{ 'TODAY'S MISSION' | translate }}</p>
          <div class="status__cont__content__mission__cal__time">
            <img src="../../../../assets/images/time.svg" alt="time" width="20" height="20" />
            <p>2023-09-28 (GMT+8)</p>
          </div>
        </div>
        <div class="status__cont__content__mission__btn">
          <input
            type="button"
            value="Goto previous Date"
            class="status__cont__content__mission__btn__send"
          />
        </div>
      </div>
      <div class="status__cont__content__name">
        <h5>{{ 'JOHN DOE' | translate }}</h5>
        <div class="status__cont__content__name__goal">
          <p style="border-right: 1px solid; font-size: 10px; font-weight: 400">
            <br /><br />{{ 'GOAL:' | translate }}<br />{{ 'CURRENT:' | translate }} <br />{{
              'REMAINING:' | translate
            }}
          </p>
          <p style="border-right: 1px solid; font-size: 12px; font-weight: 400">
            {{ 'MAIN 🍚' | translate }} <br />5 <br />6 <br />
            2
          </p>
          <p style="border-right: 1px solid; font-size: 12px; font-weight: 400">
            {{ 'MEAT 🥩' | translate }} <br />2 <br />1 <br />
            3
          </p>
          <p style="border-right: 1px solid; font-size: 12px; font-weight: 400">
            {{ 'VEGGIE 🥦' | translate }} <br />2 <br />1 <br />
            3
          </p>
          <p style="border-right: 1px solid; font-size: 12px; font-weight: 400">
            {{ 'FRUIT🍓' | translate }} <br />2 <br />1 <br />
            3
          </p>
          <p style="font-size: 12px; font-weight: 400">
            {{ 'WATER 💧' | translate }} <br />2400 <br />2400 <br />
            2400
          </p>
        </div>
      </div>
      <div class="status__cont__content__supp">
        <h5>
          {{ 'SUPPLEMENTS' | translate }} <br />
          <span>{{ 'CONSUMED: 2 BR, 3 WH' | translate }}</span>
        </h5>
        <div class="status__cont__content__supp__goal">
          <p
            style="border-right: 1px solid; font-size: 10px; font-weight: 400; font-family: Poppins"
          >
            <br />{{ 'GOAL:' | translate }}<br />{{ 'CURRENT:' | translate }} <br />{{
              'REMAINING:' | translate
            }}
          </p>
          <p
            style="border-right: 1px solid; font-size: 12px; font-weight: 400; font-family: Poppins"
          >
            {{ 'MAIN 🍚' | translate }} <br />5 <br />6 <br />
            2
          </p>
          <p
            style="border-right: 1px solid; font-size: 12px; font-weight: 400; font-family: Poppins"
          >
            {{ 'MEAT 🥩' | translate }} <br />2 <br />1 <br />
            3
          </p>
          <p
            style="border-right: 1px solid; font-size: 12px; font-weight: 400; font-family: Poppins"
          >
            {{ 'VEGGIE 🥦' | translate }} <br />2 <br />1 <br />
            3
          </p>
          <p
            style="border-right: 1px solid; font-size: 12px; font-weight: 400; font-family: Poppins"
          >
            {{ 'FRUIT🍓' | translate }}<br />2 <br />1 <br />
            3
          </p>
          <p style="font-size: 12px; font-weight: 400; font-family: Poppins">
            {{ 'WATER 💧' | translate }} <br />2400 <br />2400 <br />
            2400
          </p>
        </div>
      </div>
      <div class="status__cont__content__msg">
        <div class="status__cont__content__msg__tick">
          <h5>Massage form the Tutor:</h5>
          <img src="../../../../assets/images/tick.svg" alt="tick" />
        </div>
        <p>
          {{ 'KEEP EXERCISE AT LEAST 2 HOUR A DAY. KEEP EXERCISE AT LEAST 2 HOUR ' | translate }}
          <br />
          a day.
          {{ 'KEEP EXERCISE AT LEAST 2 HOUR A DAY. KEEP EXERCISE AT LEAST 2 HOUR ' | translate }}
          <span>See more...</span>
        </p>
      </div>
    </div>
    <div class="status__cont__profile">
      <div class="status__cont__profile__link">
        <a>{{ 'PERSONAL PROFILE' | translate }}</a>
      </div>
      <div style="display: grid; gap: 10px">
        <div class="status__cont__profile__period">
          <label>{{ 'PERIOD' | translate }}</label>
          <label class="toggle-switch">
            <input type="checkbox" [(ngModel)]="isChecked" (change)="toggleChanged()" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="status__cont__profile__weight">
          <div
            [ngClass]="{
              input: true
            }"
          >
            <label>{{ 'WEIGHT (KG)' | translate }}</label>
            <input type="text" placeholder="" formControlName="" />

            <p class="input__errorMsg">{{ 'WEIGHT IS REQUIRED.' | translate }}</p>
          </div>
        </div>
        <div class="status__cont__profile__fat">
          <div
            [ngClass]="{
              input: true
            }"
          >
            <label>{{ 'BODY FAT (%) ' | translate }}</label>
            <input type="text" placeholder="" formControlName="" />

            <p class="input__errorMsg">{{ 'BODY FAT IS REQUIRED. ' | translate }}</p>
          </div>

          <div
            [ngClass]="{
              input: true
            }"
          >
            <label>{{ 'BODY MASS (KG)' | translate }}</label>
            <input type="text" placeholder="80" formControlName="80" />

            <p class="input__errorMsg">{{ 'BODY FAT IS REQUIRED. ' | translate }}</p>
          </div>
        </div>
        <div class="status__cont__profile__upload">
          <div class="status__cont__profile__upload__btn">
            <input type="button" value="Delete" class="status__cont__profile__upload__btn__send" />
          </div>
          <div class="status__cont__profile__upload__file">
            <div
              [ngClass]="{
                input: true
              }"
            >
              <!-- <input type="file" id="upload" hidden /> -->
              <label for="upload">Upload Back Profile Photo</label>
              <input type="file" id="upload" hidden />

              <p class="input__errorMsg">{{ 'WEIGHT IS REQUIRED.' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="status__cont__profile__btn">
          <input
            type="button"
            value="{{ 'SUBMIT DAILY PROFILE' | translate }}"
            class="status__cont__profile__btn__daily"
          />
        </div>
      </div>
    </div>
    <div class="status__cont__meal">
      <div class="status__cont__meal__link">
        <a>{{ 'MEAL MANAGEMENT' | translate }}</a>
      </div>
      <div
        [ngClass]="{
          input: true
        }"
      >
        <input type="text" placeholder="+ Add Breakfast" id="text" formControlName="text" />
        <!-- <p class="input__errorMsg">Please Enter Valid Password</p> -->
        <!-- <p class="input__info">At least 8 digits</p> -->
      </div>
      <div
        [ngClass]="{
          input: true
        }"
      >
        <input type="text" placeholder="+ Add Breakfast" id="text" formControlName="text" />
        <!-- <p class="input__errorMsg">Please Enter Valid Password</p> -->
        <!-- <p class="input__info">At least 8 digits</p> -->
      </div>
      <div
        [ngClass]="{
          input: true
        }"
      >
        <input type="text" placeholder="+ Add Breakfast" id="text" formControlName="text" />
        <!-- <p class="input__errorMsg">Please Enter Valid Password</p> -->
        <!-- <p class="input__info">At least 8 digits</p> -->
      </div>
      <div
        [ngClass]="{
          input: true
        }"
      >
        <input type="text" placeholder="+ Add Breakfast" id="text" formControlName="text" />
        <!-- <p class="input__errorMsg">Please Enter Valid Password</p> -->
        <!-- <p class="input__info">At least 8 digits</p> -->
      </div>
    </div>
  </div>
</div>
