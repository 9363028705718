<div class="forgot-password">
  <div class="forgot-password__cont appcontainer">
    <div class="forgot-password__cont__lang">
      <app-language-selector></app-language-selector>
    </div>
    <div class="forgot-password__cont__top">
      <img src="../../../../assets/images/logo1.png" width="140" height="102" />

      <p *ngIf="!otpSent">{{ 'FORGOT PASSWORD' | translate }}</p>
      <p *ngIf="otpSent && !otpVerified">{{ 'VERIFICATION' | translate }}</p>
      <p *ngIf="otpVerified">{{ 'NEW PASSWORD' | translate }}</p>
    </div>
    <!-- <form class="forgot-password__cont__form" action="" [formGroup]="forgotpasswordForm">
      <div
        [ngClass]="{
          input: true,
          input___error: countryCode && forgotpasswordForm.get('country_code')?.hasError('required')
        }"
      >
        <select formControlName="country_code" placeholder="Select Country Code">
          <option value="" disabled>Select Country Code</option>
          <option value="+852">+852</option>
          <option value="+91">+91</option>
          <option value="+86">+86</option>
        </select>
        <p
          class="input__errorMsg"
          *ngIf="forgotpasswordForm.get('country_code')?.hasError('required')"
        >
          Country Code is required.
        </p>
      </div>
      <div
        [ngClass]="{
          input: true,
          input___error:
            phoneNumber &&
            (forgotpasswordForm.get('phone_number')?.hasError('required') ||
              forgotpasswordForm.get('phone_number')?.hasError('pattern'))
        }"
      >
        <label><span>*</span>Mobile Number</label>
        <div class="input__row">
          <input
            type="text"
            placeholder="21531 15231"
            id="phone_number"
            formControlName="phone_number"
            (input)="resetError()"
            [readOnly]="otpVerified"
          />
         
        </div>
        <p
          class="input__errorMsg"
          *ngIf="forgotpasswordForm.get('phone_number')?.hasError('required')"
        >
          Mobile number is required.
        </p>
        <p
          class="input__errorMsg"
          *ngIf="forgotpasswordForm.get('phone_number')?.hasError('pattern')"
        >
          Please enter a valid 8-digit mobile number.
        </p>
      </div>
      <div
        [ngClass]="{
          input: true,
          input___error:
            otp &&
            (forgotpasswordForm.get('otp')?.hasError('required') ||
              forgotpasswordForm.get('otp')?.hasError('pattern'))
        }"
        *ngIf="otpSent && forgotpasswordForm.get('phone_number')!.valid"
      >
        <label><span>*</span>OTP</label>
        <div class="input__row">
          <input
            type="text"
            placeholder="******"
            formControlName="otp"
            (input)="resetError()"
            [readOnly]="otpVerified"
          />
        </div>
        <p class="input__errorMsg" *ngIf="forgotpasswordForm.get('otp')?.hasError('required')">
          Otp is required.
        </p>
        <p class="input__errorMsg" *ngIf="forgotpasswordForm.get('otp')?.hasError('pattern')">
          Please enter a valid 6-digit otp.
        </p>
      </div>
      <input
        type="button"
        value="Send Otp"
        class="primaryBtn"
        (click)="sendOTP()"
        *ngIf="!otpSent"
      />
      <input
        type="button"
        value="Verify Otp"
        class="primaryBtn"
        (click)="verifyOTP()"
        *ngIf="otpSent && forgotpasswordForm.get('phone_number')!.valid && !otpVerified"
      />

      <ng-container *ngIf="otpVerified">
        <div
          [ngClass]="{
            input: true,
            input___error: password && forgotpasswordForm.get('password')?.hasError('required')
          }"
        >
          <label><span>*</span>Password</label>
          <input
            type="password"
            placeholder="**************"
            formControlName="password"
            (input)="resetError()"
          />
          <p
            class="input__errorMsg"
            *ngIf="forgotpasswordForm.get('password')?.hasError('required')"
          >
            Password is required.
          </p>
        </div>
        <div
          [ngClass]="{
            input: true,
            input___error:
              confirmPassword && forgotpasswordForm.get('confirmPassword')?.hasError('required')
          }"
        >
          <label><span>*</span>Confirm Password</label>
          <input
            type="password"
            placeholder="**************"
            formControlName="confirmPassword"
            (input)="resetError()"
          />
          <p
            class="input__errorMsg"
            *ngIf="forgotpasswordForm.get('confirmPassword')?.hasError('required')"
          >
            Confirm Password is required.
          </p>
        </div>
        <input type="button" value="Reset Password" class="primaryBtn" (click)="onSubmit()" />
      </ng-container>

    </form> -->
    <form class="forgot-password__cont__form" [formGroup]="forgotpasswordForm">
      <ng-container *ngIf="!otpVerified">
        <div
          [ngClass]="{
            input: true,
            input___error:
              countryCode && forgotpasswordForm.get('country_code')?.hasError('required')
          }"
        >
          <select formControlName="country_code" placeholder="Select Country Code">
            <option value="" disabled>{{ 'SELECT COUNTRY CODE' | translate }}</option>
            <option value="+852">+852</option>
            <option value="+86">+86</option>
            <option value="+81">+81</option>
          </select>
          <p
            class="input__errorMsg"
            *ngIf="forgotpasswordForm.get('country_code')?.hasError('required')"
          >
            {{ 'COUNTRY CODE IS REQUIRED.' | translate }}
          </p>
        </div>
        <div
          [ngClass]="{
            input: true,
            relative: true,
            input___error:
              phoneNumber &&
              (forgotpasswordForm.get('phone_number')?.hasError('required') ||
                forgotpasswordForm.get('phone_number')?.hasError('pattern'))
          }"
        >
          <input
            type="text"
            placeholder="85558555"
            id="phone_number"
            formControlName="phone_number"
            (input)="resetError()"
          />
          <p
            class="input__errorMsg"
            *ngIf="forgotpasswordForm.get('phone_number')?.hasError('required')"
          >
            {{ 'MOBILE NUMBER IS REQUIRED.' | translate }}
          </p>
          <p
            class="input__errorMsg"
            *ngIf="forgotpasswordForm.get('phone_number')?.hasError('pattern')"
          >
            {{ 'PLEASE ENTER A VALID 8-DIGIT MOBILE NUMBER.' | translate }}
          </p>
        </div>
        <div
          [ngClass]="{
            input: true,
            input___error:
              otp &&
              (forgotpasswordForm.get('otp')?.hasError('required') ||
                forgotpasswordForm.get('otp')?.hasError('pattern'))
          }"
          *ngIf="otpSent && forgotpasswordForm.get('phone_number')!.valid"
        >
          <input
            type="text"
            placeholder="Please Enter OTP"
            formControlName="otp"
            (input)="resetError()"
            [readOnly]="otpVerified"
          />
          <p class="input__errorMsg" *ngIf="forgotpasswordForm.get('otp')?.hasError('required')">
            {{ 'OTP IS REQUIRED.' | translate }}
          </p>
          <p class="input__errorMsg" *ngIf="forgotpasswordForm.get('otp')?.hasError('pattern')">
            {{ '  PLEASE ENTER A VALID 4-DIGIT OTP.' | translate }}
          </p>
        </div>
      </ng-container>
      <ng-container *ngIf="otpVerified">
        <div
          [ngClass]="{
            input: true,
            input___error: password && forgotpasswordForm.get('password')?.hasError('required')
          }"
        >
          <label><span>*</span>{{ 'PASSWORD' | translate }}</label>
          <input
            type="password"
            placeholder="**************"
            formControlName="password"
            (input)="resetError()"
          />
          <p
            class="input__errorMsg"
            *ngIf="forgotpasswordForm.get('password')?.hasError('required')"
          >
            {{ 'PASSWORD IS REQUIRED.' | translate }}
          </p>
        </div>
        <div
          [ngClass]="{
            input: true,
            input___error:
              passwordMismatch ||
              (confirmPassword && forgotpasswordForm.get('confirmPassword')?.hasError('required'))
          }"
        >
          <label><span>*</span>{{ 'CONFIRM PASSWORD' | translate }}</label>
          <input
            type="password"
            placeholder="**************"
            formControlName="confirmPassword"
            (input)="resetError()"
          />
          <p
            class="input__errorMsg"
            *ngIf="forgotpasswordForm.get('confirmPassword')?.hasError('required')"
          >
            {{ 'CONFIRM PASSWORD IS REQUIRED.' | translate }}
          </p>
          <p class="input__errorMsg" *ngIf="passwordMismatch">
            {{ 'PASSWORDS DO NOT MATCH' | translate }}
          </p>
        </div>
      </ng-container>
      <div class="registration__cont__form__forgot">
        <!-- <a href="">Forgot your password?</a> -->
        <input
          type="button"
          [value]="'SEND OTP' | translate"
          class="primaryBtn"
          (click)="sendOTP()"
          *ngIf="!otpSent"
        />
        <input
          type="button"
          [value]="'VERIFY OTP' | translate"
          class="primaryBtn"
          (click)="verifyOTP()"
          *ngIf="otpSent && forgotpasswordForm.get('phone_number')!.valid && !otpVerified"
        />
        <input
          type="button"
          [value]="'RESET PASSWORD' | translate"
          class="primaryBtn"
          (click)="onSubmit()"
          *ngIf="otpVerified"
        />
      </div>
    </form>
  </div>
</div>
