<div class="login">
  <div class="login__cont appcontainer">
    <div class="login__cont__lang">
      <app-language-selector></app-language-selector>
    </div>
    <div class="login__cont__top">
      <img src="../../../../assets/images/logo1.png" width="140" height="102" />
      <p>{{ 'LOGIN' | translate }}</p>
    </div>
    <form [formGroup]="loginForm" class="login__cont__form">
      <div
        [ngClass]="{
          input: true,
          input___error: countryCode && loginForm.get('country_code')?.hasError('required')
        }"
      >
        <select formControlName="country_code" placeholder="Select Country Code">
          <option value="" disabled>{{ 'SELECT COUNTRY CODE' | translate }}</option>
          <option value="+852">+852</option>
          <option value="+86">+86</option>
          <option value="+81">+81</option>
        </select>
        <p class="input__errorMsg" *ngIf="loginForm.get('country_code')?.hasError('required')">
          {{ 'COUNTRY CODE IS REQUIRED.' | translate }}
        </p>
      </div>
      <div
        [ngClass]="{
          input: true,
          input___error:
            phoneNumber &&
            (loginForm.get('phone_number')?.hasError('required') ||
              loginForm.get('phone_number')?.hasError('pattern'))
        }"
      >
        <input
          type="text"
          placeholder="85558555"
          id="phone_number"
          formControlName="phone_number"
          (input)="resetError()"
        />
        <p class="input__errorMsg" *ngIf="loginForm.get('phone_number')?.hasError('required')">
          {{ 'MOBILE NUMBER IS REQUIRED.' | translate }}
        </p>
        <p class="input__errorMsg" *ngIf="loginForm.get('phone_number')?.hasError('pattern')">
          {{ 'PLEASE ENTER A VALID 8-DIGIT MOBILE NUMBER.' | translate }}
        </p>
      </div>
      <div
        [ngClass]="{
          input: true,
          input___error: password && loginForm.get('password')?.hasError('required')
        }"
      >
        <input
          type="password"
          placeholder="**************"
          id="password"
          formControlName="password"
          (input)="resetError()"
        />
        <p class="input__errorMsg" *ngIf="loginForm.get('password')?.hasError('required')">
          {{ 'PLEASE ENTER VALID PASSWORD' | translate }}
        </p>
        <!-- <p class="input__info">At least 8 digits</p> -->
      </div>
      <div class="login__cont__form__forgot">
        <a href="/{{ currentLang }}/forgot-password">{{ 'FORGOT YOUR PASSWORD?' | translate }}</a>
      </div>

      <input
        type="button"
        [value]="'LOGIN BTN' | translate"
        class="primaryBtn"
        (click)="onSubmit()"
      />

      <a href="/{{ currentLang }}/registration" class="secondaryBtn">{{
        'REGISTER' | translate
      }}</a>
    </form>
    <!-- <div class="login__cont__mid">
      <div class="login__cont__mid__top">
        <div>
          <select placeholder="Select Country Code" formControlName="country code">
            <option value="0">Select Country Code</option>
            <option value="1">+91</option>
            <option value="2">+22</option>
          </select>
        </div>
        <div>
          <input
            type="number"
            placeholder="Enter Phone Number"
            id="phone_number"
            formControlName="phone_number"
          />
          <p>Type after +91</p>
        </div>
        <div>
          <input
            type="password"
            placeholder="Enter Password"
            id="password"
            formControlName="password"
          />
          <p>Atleast 8 digit</p>
        </div>
      </div>
      <div class="login__cont__mid__bottom">
        <a>Forgot your Password?</a>
        <div class="login__cont__mid__bottom__btn">Login</div>
      </div>
    </div>
    <div class="login__cont__btn">Register</div> -->
  </div>
</div>
