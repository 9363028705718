<div class="fileUpload">
  <div *ngIf="!showCroppedImage" class="upload-btn-wrapper">
    <button class="btn">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.75 20V9.8125L10.5 13.0625L8.75 11.25L15 5L21.25 11.25L19.5 13.0625L16.25 9.8125V20H13.75ZM7.5 25C6.8125 25 6.22375 24.755 5.73375 24.265C5.24375 23.775 4.99917 23.1867 5 22.5V18.75H7.5V22.5H22.5V18.75H25V22.5C25 23.1875 24.755 23.7763 24.265 24.2663C23.775 24.7563 23.1867 25.0008 22.5 25H7.5Z"
          fill="#79E0E8"
        />
      </svg>
      <p>{{ childLabel }}</p>
    </button>
    <input
      type="file"
      name="myfile"
      #fileInput
      (change)="fileChangeEvent($event)"
      accept="image/*"
    />
  </div>
  <div class="fileUpload__preview" *ngIf="showCroppedImage">
    <img [src]="croppedImageLink.toString()" alt="Cropped Image" />
    <a class="fileUpload__delete" (click)="deleteFile()">{{ 'DELETE' | translate }}</a>
  </div>
  <div *ngIf="fileSizeError" class="error">
    {{ fileSizeError }}
  </div>
</div>
<div class="crop_popup" *ngIf="showCropPopup">
  <ng-container *ngIf="!showLoading">
    <image-cropper
      *ngIf="type !== 'meal'"
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="false"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
    <image-cropper
      *ngIf="type === 'meal'"
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="179 / 134"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
    <div class="crop_popup__row">
      <a (click)="setCropImage()" class="primaryBlueBtn primaryBlueBtn__smallHeight">{{
        'SET IMAGE' | translate
      }}</a>
      <a (click)="hideCropPopup()" class="secondaryBtn">{{ 'CLOSE' | translate }}</a>
    </div>
  </ng-container>
  <div *ngIf="showLoading">Please wait we are uploading your image.</div>
</div>
