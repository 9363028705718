<div class="app-container home-screen">
  <app-app-header />
  <!-- <app-image-upload /> -->
  <ng-container *ngIf="!noSecretMsg">
    <div class="today-mission">
      <h3>{{ "TODAY'S MISSION" | translate }}</h3>
      <div class="today-mission__row">
        <div class="today-mission__row__date">
          <img src="../../../../assets/images/time.svg" alt="time" width="20" height="20" />
          <p>{{ formattedDate }}</p>
        </div>
        <a class="today-mission__row__btn" (click)="prevDate()">{{
          'GO TO PREVIOUS DATE' | translate
        }}</a>
      </div>
    </div>
    <div class="daily-profile-alert" *ngIf="showProfileAlert">
      <div class="daily-profile-alert__cont">
        <p>
          {{ homeData?.user_name }},
          {{ "DON'T FORGET TO UPLOAD DAILY BODY DATA" | translate }}
        </p>
        <a (click)="personalClick()">{{ "LET'S UPLOAD" | translate }}</a>
      </div>
    </div>
    <div class="meal-summary">
      <h2
        *ngIf="
          homeData !== undefined &&
          homeData.user_name !== undefined &&
          homeData.user_name !== null &&
          homeData.user_name !== ''
        "
      >
        {{ homeData.user_name }}
      </h2>
      <div class="meal-summary__table" *ngIf="homeData !== undefined">
        <div class="meal-summary__table__col">
          <p>&nbsp;</p>
          <p>{{ 'GOAL:' | translate }}</p>
          <p>{{ 'CURRENT:' | translate }}</p>
          <p>{{ 'REMAINING:' | translate }}</p>
        </div>
        <div class="meal-summary__table__col">
          <p>{{ 'MAIN 🍚' | translate }}</p>
          <p>{{ homeData.goal.rice_noodels }}</p>
          <p>{{ homeData.current_code.rice_noodels }}</p>
          <p>{{ homeData.remaning_code.rice_noodels }}</p>
        </div>
        <div class="meal-summary__table__col">
          <p>{{ 'MEAT 🥩' | translate }}</p>
          <p>{{ homeData.goal.meat }}</p>
          <p>{{ homeData.current_code.meat }}</p>
          <p>{{ homeData.remaning_code.meat }}</p>
        </div>
        <div class="meal-summary__table__col">
          <p>{{ 'VEGGIE 🥦' | translate }}</p>
          <p>{{ homeData.goal.vegitables }}</p>
          <p>{{ homeData.current_code.vegitables }}</p>
          <p>{{ homeData.remaning_code.vegitables }}</p>
        </div>
        <div class="meal-summary__table__col">
          <p>{{ 'FRUIT🍓' | translate }}</p>
          <p>{{ homeData.goal.fruits }}</p>
          <p>{{ homeData.current_code.fruits }}</p>
          <p>{{ homeData.remaning_code.fruits }}</p>
        </div>
        <div class="meal-summary__table__col">
          <p>{{ 'WATER 💧' | translate }}</p>
          <p>{{ homeData.goal.water }}</p>
          <p>{{ homeData.current_code.water }}</p>
          <p>{{ homeData.remaning_code.water }}</p>
        </div>
      </div>
    </div>
    <div
      class="supplement"
      *ngIf="
        homeData !== undefined &&
        homeData.assigned_suppliment !== undefined &&
        homeData.assigned_suppliment.length > 0
      "
    >
      <h2>{{ 'SUPPLEMENTS' | translate }}</h2>
      <div class="supplement__cont">
        <div class="supplement__cont__box" *ngFor="let data of homeData.assigned_suppliment">
          <img
            src="{{ data.suppliment.image }}"
            width="54"
            height="17"
            alt="{{ data.suppliment.name }}"
          />
          <p class="supplement__cont__box__input">
            {{ getConsumedQuantity(data.suppliment) }} / {{ data.quantity }}
          </p>
        </div>
      </div>
    </div>
    <div
      [ngClass]="{ 'tutor-message': true, 'tutor-message___full': showFullMsg }"
      *ngIf="
        homeData !== undefined &&
        homeData.message_from_tutor !== undefined &&
        homeData.message_from_tutor !== null
      "
    >
      <div [ngClass]="{ 'tutor-message__row': true }">
        <h3>{{ 'MESSAGE FROM THE TUTOR:' | translate }}</h3>
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99967 2.00757C4.33301 2.00757 1.33301 5.00757 1.33301 8.67423C1.33301 12.3409 4.33301 15.3409 7.99967 15.3409C11.6663 15.3409 14.6663 12.3409 14.6663 8.67423C14.6663 5.00757 11.6663 2.00757 7.99967 2.00757ZM6.66634 12.0076L3.33301 8.67423L4.27301 7.73423L6.66634 10.1209L11.7263 5.0609L12.6663 6.00757L6.66634 12.0076Z"
            fill="#00B112"
          />
        </svg>
      </div>
      <pre [innerHTML]="formatMessage(homeData.message_from_tutor.message)"></pre>
      <!-- <a class="tutor-message__row" (click)="showMsg()">{{
        !showFullMsg ? 'show more' : 'show less'
      }}</a> -->
    </div>
    <div [ngClass]="{ 'personal-profile': true, 'personal-profile___show': showPersonal }">
      <div class="personal-profile__header" (click)="personalClick()">
        {{ 'DAILY BODY DATA' | translate }}
      </div>
      <div class="personal-profile__body" *ngIf="showPersonal">
        <form action="" [formGroup]="personalProfileForm">
          <div class="personal-profile__body__period" *ngIf="homeData.gender === '2'">
            <p>{{ 'PERIOD' | translate }}</p>
            <label class="switch">
              <input type="checkbox" formControlName="meanstation_cycle" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="grid grid___same gap___df">
            <div
              [ngClass]="{
                input: true,
                input___error: weight && personalProfileForm.get('weight')?.hasError('required')
              }"
            >
              <label>{{ 'WEIGHT (KG)' | translate }}</label>
              <input
                type="number"
                formControlName="weight"
                (input)="resetError(); calculateBodyMass()"
              />
              <p
                class="input__errorMsg"
                *ngIf="personalProfileForm.get('weight')?.hasError('required')"
              >
                {{ 'WEIGHT IS REQUIRED.' | translate }}
              </p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: poopoo && personalProfileForm.get('poopoo')?.hasError('required')
              }"
            >
              <label>{{ 'BOWEL MOVEMENT' | translate }}</label>
              <!-- <input type="number" formControlName="poopoo" (input)="resetError()" /> -->
              <select formControlName="poopoo">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
              <p
                class="input__errorMsg"
                *ngIf="personalProfileForm.get('poopoo')?.hasError('required')"
              >
                {{ ' BOWEL MOVEMENT IS REQUIRED. ' | translate }}
              </p>
            </div>
          </div>

          <div class="grid grid___same gap___df">
            <div
              [ngClass]="{
                input: true,
                input___error: body_fat && personalProfileForm.get('body_fat')?.hasError('required')
              }"
            >
              <label>{{ 'BODY FAT (%) ' | translate }} </label>
              <input
                type="number"
                formControlName="body_fat"
                (input)="resetError(); calculateBodyMass()"
              />
              <p
                class="input__errorMsg"
                *ngIf="personalProfileForm.get('body_fat')?.hasError('required')"
              >
                {{ ' BODY FAT IS REQUIRED. ' | translate }}
              </p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error:
                  body_mass && personalProfileForm.get('body_mass')?.hasError('required')
              }"
            >
              <label>{{ 'FAT MASS (KG)' | translate }}</label>
              <input
                type="number"
                formControlName="body_mass"
                (input)="resetError()"
                readonly
                value="{{ calculatedBodyMass }}"
              />
              <p
                class="input__errorMsg"
                *ngIf="personalProfileForm.get('body_mass')?.hasError('required')"
              >
                {{ 'BODY MASS IS REQUIRED. ' | translate }}
              </p>
            </div>
          </div>
          <app-image-upload
            [uploadedFiles]="uploadedFiles"
            (deleteLastFile)="deleteLastFile($event)"
            (fileSelected)="fileChangeEvent($event)"
            [index]="0"
            [childLabel]="'UPLOAD FRONT PROFILE PHOTO' | translate"
            [type]="'profile'"
          />
          <app-image-upload
            [uploadedFiles]="uploadedFiles"
            (deleteLastFile)="deleteLastFile($event)"
            (fileSelected)="fileChangeEvent($event)"
            [index]="1"
            [childLabel]="'UPLOAD SIDE PROFILE PHOTO' | translate"
            [type]="'profile'"
          />
          <app-image-upload
            [uploadedFiles]="uploadedFiles"
            (deleteLastFile)="deleteLastFile($event)"
            (fileSelected)="fileChangeEvent($event)"
            [childLabel]="'UPLOAD BACK PROFILE PHOTO' | translate"
            [index]="2"
            [type]="'profile'"
          />
          <input
            type="button"
            value="{{ 'SUBMIT DAILY PROFILE' | translate }}"
            class="primaryBtn primaryBtn__smallHeight"
            *ngIf="notToday"
            (click)="onProfileSubmit()"
          />
        </form>
      </div>
    </div>
    <div [ngClass]="{ 'meal-management': true, 'meal-management___show': showMeal }">
      <div class="meal-management__header" (click)="mealClick()">
        {{ 'MEAL MANAGEMENT' | translate }}
      </div>
      <div class="meal-management__body" *ngIf="showMeal">
        <div class="meal-management__body__btn" (click)="showMealFormClick('Breakfast')">
          <p>{{ ' + ADD BREAKFAST ' | translate }}</p>
        </div>
        <div class="meal-management__body__btn" (click)="showMealFormClick('Lunch')">
          <p>{{ ' + ADD LUNCH ' | translate }}</p>
        </div>
        <div class="meal-management__body__btn" (click)="showMealFormClick('Dinner')">
          <p>{{ ' + ADD DINNER ' | translate }}</p>
        </div>
        <div class="meal-management__body__btn" (click)="showMealFormClick('Snacks')">
          <p>{{ ' + ADD SNACKS ' | translate }}</p>
        </div>
      </div>
    </div>
  </ng-container>
  <app-app-footer />
  <div class="app-container meal-popup" *ngIf="showMealForm">
    <div class="meal-popup__header">
      <a class="meal-popup__header__back" (click)="hideMeal()"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 6L5 12M5 12L11 18M5 12L19 12"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </a>
      <p class="meal-popup__header__title">{{ '+ ADD ' | translate }} {{ mealType }}</p>
    </div>
    <form class="meal-popup__form" [formGroup]="mealForm">
      <div class="meal-popup__form__time grid grid___space-between">
        <p>{{ 'SELECT TIME:' | translate }}</p>
        <!-- <a class="meal-popup__form__time__btn"> {{ formattedTime }}</a> -->
        <input
          type="time"
          class="meal-popup__form__time__btn"
          [(ngModel)]="formattedTime"
          formControlName="time"
        />
      </div>
      <div class="meal-popup__form__row">
        <div class="meal-popup__form__row__left">
          <app-image-upload
            [uploadedFiles]="mealUploadedFiles"
            (deleteLastFile)="deleteMealLastFile($event)"
            (fileSelected)="mealFileChangeEvent($event)"
            [index]="0"
            [childLabel]="'UPLOAD MEAL PHOTO 1' | translate"
            [type]="'meal'"
          />
          <app-image-upload
            [uploadedFiles]="mealUploadedFiles"
            (deleteLastFile)="deleteMealLastFile($event)"
            (fileSelected)="mealFileChangeEvent($event)"
            [index]="1"
            [childLabel]="'UPLOAD MEAL PHOTO 2' | translate"
            [type]="'meal'"
          />
          <app-image-upload
            [uploadedFiles]="mealUploadedFiles"
            (deleteLastFile)="deleteMealLastFile($event)"
            (fileSelected)="mealFileChangeEvent($event)"
            [index]="2"
            [childLabel]="'UPLOAD MEAL PHOTO 3' | translate"
            [type]="'meal'"
          />
        </div>
        <div class="meal-popup__form__row__right">
          <div class="meal-popup__form__row__right__input">
            <label>主</label>
            <input type="number" formControlName="rice" (input)="resetError()" [ngModel]="rice" />
          </div>
          <div class="meal-popup__form__row__right__input">
            <label>肉</label>
            <input type="number" formControlName="meat" (input)="resetError()" [ngModel]="meat" />
          </div>
          <div class="meal-popup__form__row__right__input">
            <label>菜</label>
            <input type="number" formControlName="veg" (input)="resetError()" [ngModel]="veg" />
          </div>
          <div class="meal-popup__form__row__right__input">
            <label>果</label>
            <input type="number" formControlName="fruit" (input)="resetError()" [ngModel]="fruit" />
          </div>
          <div class="meal-popup__form__row__right__input">
            <label>水</label>
            <input type="number" formControlName="water" (input)="resetError()" [ngModel]="water" />
          </div>
        </div>
      </div>
      <div
        class="supplement"
        *ngIf="
          homeData !== undefined &&
          homeData.assigned_suppliment !== undefined &&
          homeData.assigned_suppliment.length > 0
        "
      >
        <h2>Supplements</h2>
        <div class="supplement__cont">
          <div class="supplement__cont__box" *ngFor="let data of homeData.assigned_suppliment">
            <img
              src="{{ data.suppliment.image }}"
              width="54"
              height="17"
              alt="{{ data.suppliment.name }}"
            />
            <input
              type="text"
              class="supplement__cont__box__input"
              id="{{ data.suppliment.id }}"
              placeholder="qty"
            />
            <small class="supplement__cont__box__text"
              >{{ getConsumedQuantity(data.suppliment) }} / {{ data.quantity }}</small
            >
          </div>
        </div>
      </div>
      <input
        type="button"
        value="{{ 'SUBMIT DAILY PROFILE' | translate }}"
        class="primaryBtn primaryBtn__smallHeight"
        (click)="onMealSubmit()"
      />
    </form>
  </div>
  <div class="loading" *ngIf="loading">
    <div class="loader"></div>
  </div>
  <div class="no-secret-code" *ngIf="noSecretMsg">
    <h2>
      {{ 'NO SECRET CODE ASSIGN TO YOU. PLEASE CONTACT YOUR TUTOR TO ASSIGN CODE' | translate }}
    </h2>
  </div>
</div>
