<div
  class="header container"
  [ngClass]="{ header: true, container: true, header___openMenu: showMenu === true }"
>
  <a href="/" class="header__logo"
    ><img src="../../../assets/images/logo.svg" alt="logo" width="188" height="52"
  /></a>
  <div class="header__menu">
    <a href="/">{{ 'HOME' | translate }}</a>
    <a href="/">{{ 'ABOUT US' | translate }}</a>
    <a href="/">{{ 'OUR TEAM' | translate }}</a>
    <a href="/">{{ 'PROMISES' | translate }}</a>
    <a href="/">{{ 'SUCCESSFUL CASES' | translate }}</a>
    <a href="/">{{ 'Q&A' | translate }}</a>

    <a href="/login" class="header__menu__login" *ngIf="showMenu">{{ 'LOGIN' | translate }}</a>
  </div>
  <div class="header__right">
    <a href="/login" class="header__right__login">{{ 'LOGIN' | translate }}</a>
  </div>
  <a class="header__mobIcon" (click)="showMenuFn()">
    <img
      *ngIf="!showMenu"
      src="../../../assets/images/menu-icon-open.svg"
      width="24px"
      height="24px"
      alt="menu open"
    />
    <img
      *ngIf="showMenu"
      src="../../../assets/images/menu-icon-close.svg"
      width="24px"
      height="24px"
      alt="menu open"
    />
  </a>
</div>
